import React, { Component } from "react";
import "./App.css";

export default class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: null,
      isLoaded: false,
      posts: [],
      formData: {
        username: "",
        title: "",
        content: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch("https://cztf.xyz/api/posts", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      credentials: "include",
    }).then((res) =>
      res.json().then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            posts: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
    );
  }

  handleChange(e) {
    let data = this.state.formData;
    data[e.target.name] = e.target.value;
    this.setState({
      formData: data,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log("submit!");
    console.log(this.state);
    let flag = false;
    for (const p of this.state.posts) {
      if (p["username"] === this.state.formData["username"]) {
        flag = true;
        break;
      }
    }
    if (flag) {
      fetch("https://stripes-organisms-flame-joel.trycloudflare.com/verify", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      }).then(
        (res) =>
          res.text().then((res) => {
            if (res.body === this.state.formData["username"]) {
              fetch("https://cztf.xyz/api/posts", {
                headers: {
                  "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify(this.state.formData),
              });
            }
          }),
        (err) => {
          alert("You cannot send a post using others' name!");
        }
      );
    } else {
      fetch(
        "https://stripes-organisms-flame-joel.trycloudflare.com/auth/" +
          this.state.formData["username"],
        {
          credentials: "include",
        }
      ).then(
        (res) =>
          {
            console.log(res);
            fetch("https://cztf.xyz/api/posts", {
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
              mode: 'cors',
              credentials: "include",
              body: JSON.stringify(this.state.formData),
            });
          },
        (err) => {
          alert("Failed to get the JWT from auth server!");
        }
      );
    }
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="App">
          {this.state.posts.map((post) => (
            <div>
              <h2>{post.title}</h2>
              <span>by {post.username}</span>
              <br />
              <p>{post.content}</p>
            </div>
          ))}
          <div>
            <h2>Send your Posts</h2>
            <form onSubmit={this.handleSubmit}>
              <label>
                Username:
                <input
                  type="text"
                  name="username"
                  value={this.state.formData.username}
                  onChange={this.handleChange}
                />
              </label>
              <br />
              <label>
                Title:
                <input
                  type="text"
                  name="title"
                  value={this.state.formData.title}
                  onChange={this.handleChange}
                />
              </label>
              <br />
              <label>
                Content:
                <input
                  type="text"
                  name="content"
                  value={this.state.formData.content}
                  onChange={this.handleChange}
                />
              </label>
              <br />
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}
